import 'react-datepicker/dist/react-datepicker.css';
import './Calendar.scss';

import * as pickerLocales from 'date-fns/locale';

import { getCalendarLocaleKey, LocalStorageKeys, LocalStorageService, useTranslation } from '../../utils';
import { useEffect, useState } from 'react';

import DatePicker from 'react-datepicker';
import type { TCalendarProps } from '../../utils/types';
import { availableDayArray } from '../../utils/constants/temporaries';

const isWeekday = (value: Date) => {
  const day = value.getDay();
  return availableDayArray.includes(day);
};

const Calendar = ({ date, setDate }: TCalendarProps) => {
  const [locale, setLocale] = useState(pickerLocales.enUS);

  const { t } = useTranslation();

  const addAmountOfMonth = LocalStorageService.getItem(LocalStorageKeys.AccessToken) ? 3 : 1;
  const today = new Date();
  const maxDate = new Date(new Date().setMonth(new Date().getMonth() + addAmountOfMonth));

  useEffect(() => {
    const userLocale = getCalendarLocaleKey() as keyof typeof pickerLocales;
    const localeToSet = pickerLocales[userLocale];
    setLocale(localeToSet);
  }, []);

  return (
    <div className='datePicker'>
      <h3>{t('Create appointment (client).Text_select_date_web')}</h3>
      <DatePicker
        selected={date}
        onChange={setDate}
        filterDate={isWeekday}
        inline
        monthsShown={1}
        minDate={today}
        maxDate={maxDate}
        locale={locale}
      />
    </div>
  );
};

export default Calendar;
