import { AbsoluteRoutes, NotificationType } from '../enums';
import { useLocation, useNavigate } from 'react-router-dom';

import { onLineState } from '../../atoms';
import { useAddNotification } from './useNotificationHandlers';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

export const useOnlineStatus = () => {
  const setIsOnline = useSetRecoilState(onLineState);
  const location = useLocation();
  const navigate = useNavigate();

  const pushNotification = useAddNotification();

  const goToOfflinePage = (path: AbsoluteRoutes) => {
    let route: string | undefined;

    switch (path) {
      case AbsoluteRoutes.verificationCode:
      case AbsoluteRoutes.signup:
        route = AbsoluteRoutes.login;
        break;
      case AbsoluteRoutes.expert:
      case AbsoluteRoutes.appointment:
      case AbsoluteRoutes.login:
        break;
      default:
        route = AbsoluteRoutes.expert;
        break;
    }

    !!route && navigate(route);
  };

  const updateOnlineStatus = () => {
    const isOnLine = navigator.onLine;
    const currentRoute = location.pathname as AbsoluteRoutes;

    setIsOnline(isOnLine);
    pushNotification({
      type: isOnLine ? NotificationType.Success : NotificationType.Warning,
      title: 'Connection',
      text: isOnLine
        ? 'Internet connection is restored!'
        : 'There is no internet connection. The program works in offline mode!',
    });

    !isOnLine && goToOfflinePage(currentRoute);
  };

  useEffect(() => {
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
    // only when mounting component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
};
