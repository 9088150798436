export function formatDateForRequest(date: Date | null) {
  if (!date) return null;
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function getFirstDayOfMonthForRequest(date: Date | null) {
  if (!date) return null;
  const firstDay = new Date(date.getFullYear(), date.getMonth() - 1);
  return formatDateForRequest(firstDay);
}

export function getLastDayOfMonthForRequest(date: Date | null) {
  if (!date) return null;
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return formatDateForRequest(lastDay);
}

export function getLastDayOfPreviousMonthForRequest(date: Date | null) {
  if (!date) return null;
  const firstDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth.getTime() - 1);
  return formatDateForRequest(lastDayOfPreviousMonth);
}

export function getFirstDayOfNextMonthForRequest(date: Date | null) {
  if (!date) return null;
  const firstDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const firstDayOfNextMonth = new Date(firstDayOfCurrentMonth);
  firstDayOfNextMonth.setMonth(firstDayOfCurrentMonth.getMonth() + 1);
  return formatDateForRequest(firstDayOfNextMonth);
}

export function getDatePlusThreeMonth(date: Date | null) {
  if (!date) return null;
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + 3);
  newDate.setDate(newDate.getDate() + 1);
  return formatDateForRequest(newDate);
}
