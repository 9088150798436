import { AbsoluteRoutes, ScrollToTop, useFetchTranslation, useOnlineStatus } from './utils';
import { AuthRoute, ErrorBoundary, FullScreenLoader, Notification, PopUp, ProtectedRoute } from './components';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Suspense, lazy } from 'react';

const BookingPage = lazy(() => import('./pages/booking/BookingPage'));
const ExpertPage = lazy(() => import('./pages/expert/ExpertPage'));
const LogInPage = lazy(() => import('./pages/logIn/LogInPage'));
const ServicesPage = lazy(() => import('./pages/services/ServicesPage'));
const SignUpPage = lazy(() => import('./pages/signUp/SignUpPage'));
const VerificationPage = lazy(() => import('./pages/verification/VerificationPage'));
const AppointmentPage = lazy(() => import('./pages/appointment/AppointmentPage'));
const InvitePage = lazy(() => import('./pages/invite/InvitePage'));

const AppRouter = () => {
  // get translation data
  useFetchTranslation();
  // control online status
  useOnlineStatus();

  return (
    <>
      <ScrollToTop />
      <ErrorBoundary>
        <Suspense fallback={<FullScreenLoader />}>
          <div className='pageContainer'>
            <Routes>
              <Route path={AbsoluteRoutes.expert} element={<ProtectedRoute element={<ExpertPage />} />} />
              <Route path={AbsoluteRoutes.services} element={<ProtectedRoute element={<ServicesPage />} />} />
              <Route path={AbsoluteRoutes.booking} element={<ProtectedRoute element={<BookingPage />} />} />
              <Route path={AbsoluteRoutes.signup} element={<SignUpPage />} />
              <Route path={AbsoluteRoutes.appointment} element={<ProtectedRoute element={<AppointmentPage />} />} />
              <Route path={AbsoluteRoutes.verificationCode} element={<VerificationPage />} />
              <Route path={AbsoluteRoutes.login} element={<AuthRoute element={<LogInPage />} />} />
              <Route path={AbsoluteRoutes.invite} element={<InvitePage />} />
              <Route path='*' element={<Navigate to={AbsoluteRoutes.expert} />} />
            </Routes>
          </div>
        </Suspense>
        <PopUp />
        <Notification />
      </ErrorBoundary>
    </>
  );
};

export default AppRouter;
