import './NextButton.scss';

import { NotificationType, useAddNotification } from '../../utils';

import { TNextButtonProps } from '../../utils/types';
import { onLineState } from '../../atoms';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const NextButton = ({ link, btnText, isDisable, navState }: TNextButtonProps) => {
  const isOnLine = useRecoilValue(onLineState);
  const navigate = useNavigate();

  const pushNotification = useAddNotification();

  const goToCreateAppointment = () => {
    isOnLine
      ? navigate(link, { state: navState })
      : pushNotification({
          type: NotificationType.Warning,
          title: 'Connection',
          text: 'The server is currently unavailable - offline mode',
        });
  };

  return (
    <div className='addButton'>
      <button onClick={goToCreateAppointment} disabled={!!isDisable}>
        {btnText}
      </button>
    </div>
  );
};

export default NextButton;
