import type { TAppointment } from '../utils/types';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const apptListAtom = atom<TAppointment[]>({
  key: 'apptListState',
  default: [],
  effects_UNSTABLE: [persistAtom],
});
