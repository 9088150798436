import { API_TIMEOUT, URLS } from '../constants';

import axios from 'axios';

const authApi = axios.create({
  baseURL: URLS.API_AUTH,
  timeout: API_TIMEOUT,
});

export default authApi;
