import type { TDayOfWeek } from '../types';

export const daysOrder: { [key in TDayOfWeek]: number } = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};
export const availableDayArray = [0, 1, 2, 3, 4, 5, 6];

export const allowedCountriesList = ['us'];

export const API_TIMEOUT = 10000;
