import { API_TIMEOUT, URLS } from '../constants';

import axios from 'axios';
import { setupInterceptorsTo } from './interceptors';

const api = setupInterceptorsTo(
  axios.create({
    baseURL: URLS.API_URL,
    timeout: API_TIMEOUT,
  }),
);

export default api;
