import {
  AbsoluteRoutes,
  LocalStorageKeys,
  LocalStorageService,
  NotificationType,
  moveApptAtRequestDateString,
  post,
  useAddNotification,
  useGetUserProfile,
  useTranslation,
} from '../../../utils';
import type { TTimeRangeWithUTC, TTimeSelectorHandlersProps } from '../../../utils/types';
import { activeRequestsAtom, draftApptAtom, fullDataAtom } from '../../../atoms';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { type MouseEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export function useHandlers({ facilityId, value, moveAppt, setMoveAppt }: TTimeSelectorHandlersProps) {
  const navigate = useNavigate();

  const pushNotification = useAddNotification();
  const { getUserData } = useGetUserProfile();
  const { t } = useTranslation();

  const setActiveRequests = useSetRecoilState(activeRequestsAtom);
  const setDraftAppt = useSetRecoilState(draftApptAtom);
  const fullExpertData = useRecoilValue(fullDataAtom);

  const onMoveButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      const moveBy = e.currentTarget.getAttribute('data-move-by');
      setMoveAppt(Number(moveBy));
    },
    [setMoveAppt],
  );

  const createAppointment = useCallback(async () => {
    const phoneNumber = LocalStorageService.getItem(LocalStorageKeys.PhoneNumber);
    const token = LocalStorageService.getItem(LocalStorageKeys.AccessToken);

    if (!value) return;

    const valueWithTransformedTime: TTimeRangeWithUTC = {
      ...value,
      startsUTC: moveApptAtRequestDateString(value.startsUTC, moveAppt),
      endsUTC: moveApptAtRequestDateString(value.endsUTC, moveAppt),
    };

    if (!token && !!fullExpertData) {
      setDraftAppt({ ...valueWithTransformedTime, facilityId, moveAppt });
      pushNotification({
        type: NotificationType.Success,
        title: t('Confirmation.Text_client_appointment'),
        text: t('Confirmation.Msg_confirm_draft_appt_web'),
      });
      navigate(AbsoluteRoutes.login);
      return;
    }

    const userData = await getUserData();
    if (!userData || !fullExpertData) return;

    setActiveRequests(prev => prev + 1);
    const apptData = {
      cityId: userData.cities[0].cityId,
      clientId: userData.id,
      clientPhoneNumber: phoneNumber,
      comment: '',
      expertFacilityId: facilityId,
      serviceProviderId: fullExpertData.expertProfile.id,
      startDate: valueWithTransformedTime.startsUTC,
      endDate: valueWithTransformedTime.endsUTC,
      userName: userData.userName,
    };

    const { data, error } = await post('CREATE_APPOINTMENT', apptData);

    setActiveRequests(prev => prev - 1);

    if (!!error) {
      console.log(error);
    } else {
      !!data && navigate(AbsoluteRoutes.expert);
    }
    pushNotification({
      type: !!data ? NotificationType.Success : NotificationType.Error,
      title: t('Confirmation.Text_client_appointment'),
      text: !!data ? t('Confirmation.Text_success_expert') : t('Push_notification_msg.Cannot_create_appt_web'),
    });
  }, [
    facilityId,
    fullExpertData,
    getUserData,
    moveAppt,
    navigate,
    pushNotification,
    setActiveRequests,
    setDraftAppt,
    t,
    value,
  ]);

  return { createAppointment, onMoveButtonClick };
}
